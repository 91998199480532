.cover-slides {
  position: relative;
  overflow: hidden;
}

.cover-slides .text-center {
  position: relative;
}

/* .cover-slides img {
  width: 100%;
  height: auto;
} */

/* .cover-slides .container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
} */

.m-b-20 {
  margin-bottom: 20px;
}

.m-b-40 {
  margin-bottom: 40px;
}

.btn.hvr-hover {
  background-color: #5a5a5a;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
}

.slick-arrow {
  z-index: 10;
  background-color: #000;
  border-radius: 50%;
}

.slick-prev:before,
.slick-next:before {
  color: #fff;
}

.slick-dots li button:before {
  color: #fff;
}

.team-member {
  margin: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slick-slide {
  padding: 10px;
}

.member-img img {
  height: 250px;
  width: 300px;
  border-radius: 10px;
  margin-bottom: 10px;
  object-fit: cover;
  background-color: #ffff;

  margin-left: 0px;
}

.product-info {
  text-align: center;
}

.detail-img {
  height: 10px;
}
#footer-menu {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
#footer-menu ul {
  text-align: left;
  width: 50%;
  padding-left: 150px;
}
#footer-menu li {
  line-height: 30px;
  color: #ffff !important;
}
#footer-menu li a {
  color: #ffff !important;
}

.newsletter-content {
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.newsletter-content h2 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #fff; /* Dark text color */
}

.newsletter-content p {
  font-size: 1.1rem;
  margin-bottom: 20px;
  color: #6c757d; /* Light text color */
}

.newsletter-form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 371px;
  padding-left: 50px;
  padding-bottom: 20px;
}

.newsletter-form .form-control {
  flex: 1;
  border-radius: 25px;
  border: 1px solid #ced4da;
  padding: 10px 10px;
}

.newsletter-form .btn-primary {
  border-radius: 25px;
  padding: 10px 125px;
  background-color: #007bff;
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.newsletter-form .btn-primary:hover {
  background-color: #0056b3;
}
.product-header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.product-search .input-group .form-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.product-search #search-input {
  border-radius: 5px 0px 0px 5px;
}
.product-search #search-button {
  border-radius: 0px 5px 5px 0;
}

.form-outline {
  position: relative;
  width: 100%;
}

.form-outline input {
  width: 100%;
  padding-right: 50px; /* Space for the button */
}

.form-outline label {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
}

.form-outline input:focus + label,
.form-outline input:not(:placeholder-shown) + label {
  top: 0;
  font-size: 0.75rem;
  color: #0056b3;
}
.product-header .section-title {
  padding-bottom: 60px;
}

@media (max-width: 786px) {
  .member-img img {
    height: 250px;
    width: 300px;
    border-radius: 5px;
    margin-bottom: 10px;
    object-fit: cover;
    background-color: #d2e1ef;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-left: 20px;
  }
  .newsletter-form .btn-primary {
    padding: 10px 95px;
  }
  #footer-menu ul {
    padding-left: 100px;
  }
  .product-header {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .product-header .section-title {
    padding-bottom: 10px;
  }
  .newsletter-form {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 251px;
    padding-left: 0px;
    padding-bottom: 20px;
  }
}
/* @media (max-width: 786px) { */
/* Mobile nav menu styles */
.mobilenavmenubar {
  position: fixed;
  top: 0;
  right: -60%; /* Initially hidden off-screen */
  height: 100%;
  width: 60%;
  background-color: white; /* Background color for visibility */
  transition: right 0.3s ease; /* Smooth transition */
  z-index: 1000; /* Ensure it appears above other content */
}

.mobilenavmenubar.open {
  right: 0; /* Slide in */
}

.mobilenavmenubar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.navclose {
  padding: 20px 0px 0 135px;
}
.mobilenavmenubar li {
  padding: 5px 30px;
}

.mobilenavmenubar a {
  text-decoration: none;
  color: black; /* Change color as needed */
  display: block;
}

/* Mobile nav toggle icon */
.mobile-nav-toggle {
  cursor: pointer;
}

/* ---------------------------product-page---------------------------------------- */

.product-page {
  display: flex;
  flex-direction: row;
}

.product-filter {
  display: flex;
  flex-direction: column;
  width: 20%;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  height: 330px;
}
.product-filter-heading {
  text-align: center;
  font-size: 20px;
}
.product-page-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  width: 80%;
}
.product-filter-dropdownbox {
  margin-top: 20px;
}
.product-filter-dropdownbox h1 {
  font-size: 15px;
  padding-left: 32px;
}

.product-filter-dropdown {
  min-width: 15em;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
}
.product-filter-select {
  background: #2a2f3b;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px #2a2f3b solid;
  border-radius: 0.5em;
  padding: 10px;
  cursor: pointer;
  transition: 0.3s;
  width: 80%;
}
.product-filter-select-clicked {
  border: 2px #26489a solid;
  box-shadow: 0 0 0.8em #26489a;
}
.product-filter-select:hover {
  background: #323741;
}
.product-filter-caret {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #fff;
  transition: 0.3s;
}
.product-filter-caret-rotate {
  transform: rotate(180deg);
}
.product-filter-menu {
  list-style: none;
  padding: 0.2em 0.5em;
  background: #323741;
  border: 1px #363a43 solid;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.2);
  border-radius: 0.5em;
  color: #9fa5b5;
  position: absolute;
  top: 4em;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);
  opacity: 0;
  display: none;
  transition: 0.2s;
  z-index: 1;
}
.product-filter-menu li {
  padding: 0.7em 0.5em;
  margin: 0.3em 0;
  border-radius: 0.5em;
  cursor: pointer;
}
.product-filter-menu li:hover {
  background: #2a2d35;
}
.product-filter-active {
  background: #23242a;
}
.product-filter-menu-open {
  display: block;
  opacity: 1;
}
.product-filter-heading {
  display: flex;
  text-align: center;
  justify-content: center;
  align-item: center;
}

.arrow {
  background-color: #2c3077;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 20px;
  z-index: 99;
}

.arrow.prev {
  position: absolute;
  top: 31%;
  left: 10px;
  transform: translateX(-50%);
  border-radius: 10px;
}

.arrow.next {
  position: absolute;
  top: 41%;
  right: -10px;
  transform: translateY(-50%);
  border-radius: 10px;
}

@media (max-width: 768px) {
  .arrow.prev {
    position: absolute;
    top: 40%;
    left: 40px;
    transform: translateX(-50%);
    border-radius: 10px;
  }

  .arrow.next {
    position: absolute;
    top: 49%;
    right: 30px;
    transform: translateY(-50%);
    border-radius: 10px;
  }
  .product-filter-heading {
    display: none;
  }
  .product-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
  }
  .product-page-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-item: center;
    width: 100%;
  }
  .product-filter {
    display: flex;
    flex-direction: column;
    align-item: center;
    width: 90%;
    border-radius: 10px;
    box-shadow: none;
    height: 160px;
  }
}

/* ----------------------brand-page----------------------------- */
.brand-page-card {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  width: 100%;
}

.catalogue {
  color: var(--contrast-color);
  background: #2c3077;
  border: 0;
  padding: 10px 30px;
  transition: 0.4s;
  border-radius: 50px;
}

.product-info h4 {
  font-size: 20px;
}

.product-card {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
  height: 320px;
}
.product-card-threeline {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 10px;
  height: 350px !important;
}
.teamgradian {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home-slider {
  overflow: hidden;
  margin-bottom: 30px;
  height: 100vh;
  position: relative; /* Ensure the container is positioned for absolute children */
}

.ag-spotlight-moving_box {
  height: 100vh;
  width: 100%;
  /* background: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-spotlight-moving/images/bg.jpg)
    no-repeat scroll 50% 0 #002841; */
  background-image: url("./map.jpg");
  background-size: cover;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  z-index: 1;
  position: relative; /* Changed to relative to properly position text */
}

.ag-spotlight-moving_spotlight {
  bottom: 3.4375em;
  z-index: -1;
  width: 14.375em;
  height: 128.125em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: rgba(255, 255, 255, 0.08);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation: an-spotlight-moving-right 20s
    cubic-bezier(0.6, 0, 0.55, 0.8) infinite;
  animation: an-spotlight-moving-right 20s cubic-bezier(0.6, 0, 0.55, 0.8)
    infinite;
  -webkit-clip-path: polygon(0 0, 100% 0, 60% 100%, 40% 100%);
  clip-path: polygon(0 0, 100% 0, 60% 100%, 40% 100%);
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  position: absolute;
}

.ag-spotlight-moving_spotlight-right {
  right: -3.4375em;
}

.ag-spotlight-moving_spotlight-left {
  left: -3.4375em;
  -webkit-animation-name: an-spotlight-moving-left;
  animation-name: an-spotlight-moving-left;
}

.ag-spotlight-moving_airplane {
  top: 9.375em;
  right: -8.8125em;
  width: 8.8125em;
  height: 6.9375em;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-image: url(https://raw.githubusercontent.com/SochavaAG/example-mycode/master/pens/animation-spotlight-moving/images/airplane.png);
  /* background-image: url(https://worldmapblank.com/wp-content/uploads/2022/01/Printable-Map-of-World-with-Pins-1024x681.jpg); */
  -webkit-animation: an-airplane 20s linear backwards infinite;
  animation: an-airplane 20s linear backwards infinite;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  position: absolute;
}

.welcome-text {
  position: absolute;
  top: 50%; /* Adjust as needed */
  left: 50%; /* Adjust as needed */
  transform: translate(-50%, -50%); /* Center the text */
  color: #7e57c2;
  font-size: 2rem; /* Adjust as needed */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add shadow for readability */
  z-index: 2; /* Ensure it's above the spotlight */
  text-align: center;
}

@-webkit-keyframes an-spotlight-moving-right {
  0%,
  100% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(-75deg);
    transform: rotate(-75deg);
  }
}

@keyframes an-spotlight-moving-right {
  0%,
  100% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  50% {
    -webkit-transform: rotate(-75deg);
    transform: rotate(-75deg);
  }
}

@-webkit-keyframes an-spotlight-moving-left {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(75deg);
    transform: rotate(75deg);
  }
}

@keyframes an-spotlight-moving-left {
  0%,
  100% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  50% {
    -webkit-transform: rotate(75deg);
    transform: rotate(75deg);
  }
}

@-webkit-keyframes an-airplane {
  0%,
  85% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-43.75em, -16.25em);
    transform: translate(-43.75em, -16.25em);
  }
}

@keyframes an-airplane {
  0%,
  85% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-43.75em, -16.25em);
    transform: translate(-43.75em, -16.25em);
  }
}

.carousel {
  position: relative;
  width: 100%;
  height: 80vh;
}

.slider-button {
  font-size: 1rem;
  padding: 5px 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--unhover-background-color);
  border: 2px solid var(--unhover-border-color);
  color: var(--unhover-text-color);
  z-index: 10;
  cursor: pointer;
}

.slider-button-prev {
  left: 2rem;
  font-size: 50px;
}

.slider-button-next {
  right: 2rem;
  font-size: 50px;
}

.slider-button:hover,
.slider-button:focus {
  background-color: var(--hover-background-color);
  border: 2px solid var(--hover-border-color);
  color: var(--hover-text-color);
}

ul.slides {
  list-style-type: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
}

li.slide {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition: opacity var(--transition-delay);
}

li.slide.active {
  opacity: 1;
}

.slide > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

/* .slide-content {
  position: absolute;
  bottom: 40%;
  left: 25%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
   text-align: center;
} */

.slide-content {
  position: absolute;
  bottom: 40%; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  color: #2c3077 !important;
  /* background: rgba(0, 0, 0, 0.5); */
  background: rgb(112 112 112 / 50%);
  height: 150px; /* Adjust padding for better readability */
  border-radius: 5px;
  text-align: center;
  max-width: 80%; /* Ensure it does not overflow */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.slide-content h2 {
  margin: 0;
  font-size: 1.5rem;
  padding: 10px;
}

.slide-content p {
  margin: 5px 0 0;
}

.slides-circles {
  display: flex;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

.circle {
  height: 20px;
  width: 20px;
  margin: 10px;
  border: 3px solid white;
  border-radius: 50%;
  transition: background-color var(--transition-delay);
}

.circle.active {
  background-color: white;
}
/* ---------------------------------------home animation------------------------------ */
.image-container {
  position: relative;
  width: 100%;
  height: 380px; /* Adjust as needed */
  overflow: hidden;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../public/assets/img/animation-bg.png");
  background-size: cover;
  background-position: center;
  opacity: 1; /* Adjust the opacity as needed */
  z-index: 1; /* Place it behind the content */
}

.image-content {
  position: relative;
  z-index: 2; /* Ensure content is above the background */
}

.moving-image {
  position: absolute;
  top: 75px;
  left: -100%; /* Start the animation off-screen */
  width: 100%; /* Adjust this as needed */
  height: 300px; /* Adjust this as needed */
  animation: moveRight 15s linear infinite; /* Adjust duration as needed */
}

@keyframes moveRight {
  0% {
    left: -100%; /* Start off-screen to the left */
  }
  100% {
    left: 100%; /* Move off-screen to the right */
  }
}

@media (max-width: 768px) {
  .moving-image {
    position: absolute;
    top: 110px;
    left: -100%; /* Start the animation off-screen */
    width: 100%; /* Adjust this as needed */
    height: 300px; /* Adjust this as needed */
    animation: moveRight 15s linear infinite; /* Adjust duration as needed */
  }
}
/* ---------------------------------------loading------------------------------ */
.loading-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ffffff;
  margin: 0;
}

.loading-container {
  display: grid;
  grid-template-columns: repeat(5, 50px);
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.ball {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  animation: wave 1.5s ease-in-out infinite;
  background-color: #37bf57;
}

.ball:nth-child(2) {
  animation-delay: -0.2s;
  background-color: #49caa1;
}

.ball:nth-child(3) {
  animation-delay: -0.4s;
  background-color: #12aab4;
}

.ball:nth-child(4) {
  animation-delay: -0.6s;
  background-color: #2c88c1;
}

.ball:nth-child(5) {
  animation-delay: -0.8s;
  background-color: #6b45b1;
}

@keyframes wave {
  0%,
  100% {
    transform: translateY(30px);
  }
  50% {
    transform: translateY(-30px);
  }
}

.homemarq {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  background: #fff;
}

.homemarq-scroll {
  position: relative;
  display: flex;
  width: 80%;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent,
    #fff 30%,
    #fff 70%,
    transparent
  );
}

.homemarq-scroll div {
  white-space: nowrap;
  animation: animate var(--t) linear infinite;
  animation-delay: calc(var(--t) / -1);
}

.homemarq-scroll div:nth-child(2) {
  animation: animate2 var(--t) linear infinite;
  animation-delay: calc(var(--t) / -2);
}

@keyframes animate {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes animate2 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.homemarq-scroll div span {
  display: inline-flex;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
  background: #333;
  color: #fff;
  transition: 0.5s;
}

.homemarq-scroll div span:hover {
  background: #f52789;
  cursor: pointer;
}


@media (max-width: 768px) {
  .hero {
    height: 35vh; /* Adjust the height for smaller screens */
  }

  .slide img {
    object-position: top center; /* Adjust the image position for mobile */
  }

  .slider-button {
    width: 30px;
    height: 30px;
  }
}